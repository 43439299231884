<template>
  <div class="content trial-balance-list">
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <SelectAccountSet
            :placeholder="$t(`账簿`)"
            :name.sync="formData.accountSetUnionName"
            :code="formData.accountSetUnionCode"
            @change="changeAccountSet"
          />
        </a-col>
        <a-col :span="6">
          <a-input :disabled="true" :value="formData.businessUnitName" />
        </a-col>
        <a-col :span="12">
          <AccountingPeriodRange
            ref="AccountingPeriodRangeRef"
            :init="false"
            :businessUnitCode="formData.businessUnitCode"
            @change="formData.accountingPeriodList = $event"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showPagination="false"
      :tableProps="{
        bordered: true,
      }"
      :showNo="false"
      @search="getList"
    >
      <template v-slot:orderSn="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectAccountSet from '@/component/selectAccountSet'
import AccountingPeriodRange from '@/component/accountingPeriodRange'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'TrialBalanceList',
  components: { SelectAccountSet, AccountingPeriodRange },
  data() {
    return {
      docType: 'TrialBalance',
      formData: {
        accountSetUnionName: undefined,
        accountSetUnionCode: undefined,
        accountSetCode: undefined,
        businessUnitName: undefined,
        businessUnitCode: undefined,
        accountingPeriodList: [],
      },
      tableData: [],
      loading: false,

      columns: [],
    }
  },
  mounted() {
    this.setColumns()
  },
  activated() {
    this.searchHandle()
  },
  computed: {},
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.$refs.AccountingPeriodRangeRef.clear()
      this.setColumns()
      this.searchHandle(1)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('科目编码'),
          align: 'center',
          dataIndex: 'accountCode',
        },
        {
          title: this.$t('科目名称'),
          align: 'center',
          dataIndex: 'accountName',
          customRender: (text, record) => {
            return record.type === 'summary' ? this.$t('合计') : record.accountName
          },
        },
        {
          title: this.$t('期初余额'),
          dataIndex: 'beginningBalance',
          children: [
            {
              title: this.$t('借方'),
              align: 'center',
              width: '12%',
              dataIndex: 'beginBalanceDebitAmount',
            },
            {
              title: this.$t('贷方'),
              align: 'center',
              width: '12%',
              dataIndex: 'beginBalanceCreditAmount',
            },
          ],
        },
        {
          title: this.$t('本期发生'),
          dataIndex: 'currentPeriodAmount',
          children: [
            {
              title: this.$t('借方'),
              align: 'center',
              width: '12%',
              dataIndex: 'debitAmount',
            },
            {
              title: this.$t('贷方'),
              align: 'center',
              width: '12%',
              dataIndex: 'creditAmount',
            },
          ],
        },
        {
          title: this.$t('期末余额'),
          dataIndex: 'endBalance',
          children: [
            {
              title: this.$t('借方'),
              align: 'center',
              width: '12%',
              dataIndex: 'endBalanceDebitAmount',
            },
            {
              title: this.$t('贷方'),
              align: 'center',
              width: '12%',
              dataIndex: 'endBalanceCreditAmount',
            },
          ],
        },
      ]
    },
    searchHandle(pageNo) {
      if (!this.formData.businessUnitCode) return
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
      this.searchHandle(1)
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getTrialBalanceList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          let totalBeginBalanceDebitAmount = 0,
            totalBeginBalanceCreditAmount = 0,
            totalDebitAmount = 0,
            totalCreditAmount = 0,
            totalEndBalanceDebitAmount = 0,
            totalEndBalanceCreditAmount = 0
          this.tableData = result.map((item) => {
            // 使用一个辅助函数来确保数值计算的精确性
            const safeAdd = (num1, num2) => {
              return Number((Number(num1) + Number(num2)).toFixed(2))
            }

            //合计
            totalBeginBalanceDebitAmount = safeAdd(
              totalBeginBalanceDebitAmount,
              item.beginBalanceDirection === 'DEBIT' ? Number(item.beginBalance) : 0
            )

            totalBeginBalanceCreditAmount = safeAdd(
              totalBeginBalanceCreditAmount,
              item.beginBalanceDirection === 'CREDIT' ? Number(item.beginBalance) : 0
            )

            totalDebitAmount = safeAdd(totalDebitAmount, Number(item.debitAmount))

            totalCreditAmount = safeAdd(totalCreditAmount, Number(item.creditAmount))

            totalEndBalanceDebitAmount = safeAdd(
              totalEndBalanceDebitAmount,
              item.balanceDirection === 'DEBIT' ? Number(item.balance) : 0
            )

            totalEndBalanceCreditAmount = safeAdd(
              totalEndBalanceCreditAmount,
              item.balanceDirection === 'CREDIT' ? Number(item.balance) : 0
            )

            if (item.beginBalanceDirection === 'DEBIT') {
              this.$set(item, 'beginBalanceDebitAmount', this.$currencySymbol(item.beginBalance))
            } else {
              this.$set(item, 'beginBalanceCreditAmount', this.$currencySymbol(item.beginBalance))
            }
            if (item.balanceDirection === 'DEBIT') {
              this.$set(item, 'endBalanceDebitAmount', this.$currencySymbol(item.balance))
            } else {
              this.$set(item, 'endBalanceCreditAmount', this.$currencySymbol(item.balance))
            }
            item.creditAmount = this.$currencySymbol(item.creditAmount)
            item.debitAmount = this.$currencySymbol(item.debitAmount)

            return item
          })
          const summaryLine = {
            type: 'summary',
            beginBalanceDebitAmount: this.$currencySymbol(totalBeginBalanceDebitAmount),
            beginBalanceCreditAmount: this.$currencySymbol(totalBeginBalanceCreditAmount),
            debitAmount: this.$currencySymbol(totalDebitAmount),
            creditAmount: this.$currencySymbol(totalCreditAmount),
            endBalanceDebitAmount: this.$currencySymbol(totalEndBalanceDebitAmount),
            endBalanceCreditAmount: this.$currencySymbol(totalEndBalanceCreditAmount),
          }
          console.log('summaryLine: ', summaryLine)
          this.tableData.push(summaryLine)
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'voucherDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/voucherManage/voucherDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
  },
}
</script>
